import { Box, Typography } from "@mui/material";

const Terms = () => {
  return (
    <Box sx={{
      padding: {xs: 2, md: 4},

    }}>
      <Typography variant="h1" sx={{ fontSize: 32 }} >Términos y Condiciones de Uso de EEME</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>1. Aceptación de los Términos</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }} >Al registrarse y utilizar EEME (en adelante, "la Aplicación"), el usuario acepta cumplir y estar sujeto a los siguientes términos y condiciones. Si no está de acuerdo con estos términos, debe abstenerse de utilizar la Aplicación.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>2. Descripción del Servicio</Typography>
      
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>La Aplicación permite a los usuarios cargar, almacenar y gestionar archivos médicos, como resultados de laboratorio y recetas médicas. Este servicio está destinado al público en general y no tiene restricciones de edad o ubicación.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>3. Registro y Cuentas</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>Para utilizar la Aplicación, los usuarios deben registrarse proporcionando su nombre, apellido, correo electrónico y fecha de nacimiento. Adicionalmente, pueden optar por ingresar información médica como condiciones médicas, sexo, tipo de sangre y alergias. Los usuarios son responsables de mantener la confidencialidad de sus credenciales de acceso y de todas las actividades que ocurran bajo su cuenta.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>4. Uso del Contenido Subido por el Usuario</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>Los usuarios pueden cargar imágenes o PDFs de documentos médicos en la Aplicación. El usuario concede a EEMI el derecho de utilizar esta información con fines estadísticos o publicitarios. Sin embargo, dicha información será anonimizada y no contendrá datos que puedan identificar al usuario.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>5. Membresía y Pagos</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>La Aplicación ofrece un servicio de membresía mensual para resguardar la información médica de los usuarios. Si un usuario no realiza el pago de su membresía durante un periodo de tres meses, la información almacenada será eliminada permanentemente. Los usuarios pueden cancelar su membresía en cualquier momento sin penalización.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>6. Protección de Datos</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>Toda la información proporcionada por los usuarios será protegida mediante tecnología Blockchain, asegurando un alto nivel de seguridad y privacidad. Sin embargo, los usuarios son responsables de la correcta utilización de la Aplicación.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>7. Prohibiciones de Uso</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>Está prohibido el uso de la Aplicación para actividades de spam, publicación de contenido ofensivo o cualquier otro uso que viole las leyes aplicables. El incumplimiento de esta norma podrá resultar en la suspensión o cancelación de la cuenta del usuario.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>8. Modificaciones del Servicio</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>EEMI se reserva el derecho de modificar, suspender o discontinuar la Aplicación en cualquier momento. Los cambios serán notificados a los usuarios a través de la misma Aplicación y por correo electrónico.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>9. Terminación de Cuentas</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>Nos reservamos el derecho de suspender o cancelar cuentas de usuarios que suban contenido ofensivo o hagan un uso inapropiado de la Aplicación, sin previo aviso.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>10. Limitación de Responsabilidad</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>EEME no se hace responsable por errores, pérdidas de datos o daños resultantes del mal uso de la Aplicación por parte del usuario. Esto incluye, pero no se limita a, la eliminación o modificación de información por error.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>11. Ley Aplicable</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes de los Estados Unidos Mexicanos, incluyendo las disposiciones de la Ley de Protección de Datos Personales en Posesión de los Particulares en el sector médico.</Typography>
      <Typography variant="h2" sx={{ fontSize: 24, marginY: 4 }}>12. Contacto</Typography>
      <Typography sx={{
        lineHeight: 1.2,
        textAlign: 'justify'
      }}>Para cualquier pregunta o consulta relacionada con estos términos y condiciones, los usuarios pueden contactar a EEME en eemeapp@gmail.com</Typography>
    </Box>
  );
}

export default Terms;