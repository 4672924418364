import React, { useEffect } from 'react';
import LoggedInRoute from '../components/loggedin-route';
import { motion } from 'framer-motion';
import { pageVariants } from '../utils/animations';
import { usePatientStore } from '../stores/patient.store';
import { Box, Typography, Dialog } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../stores/user.store';
import CircledAvatar from '../components/patient/circled-avatar';
import PatientTabs from '../components/patient/patient-tabs';
import PatientForm from '../components/patient.form';
import { useFormik } from 'formik';
import PatientPhoto from '../components/patient-photo';

const DashboardPage = () => {
  const navigate = useNavigate();
  const {
    fetchPatients,
    patients,
    onGetAllFiles,
    selectedPatient,
    setSelectedPatient,
    openEditModal,
    setOpenEditModal,
    myPatient,
    onUpdatePatient,
    onGetFileRecords,
  } = usePatientStore();
  const { onLogout, user } = useUserStore();

  useEffect(() => {
    fetchPatients();
    onGetAllFiles()

  }, [fetchPatients, onGetAllFiles]);

  const createPatient = () => {
    navigate('/dashboard/crear-paciente');
  }

  const openFolder = (patient) => {
    setSelectedPatient(patient);
    onGetFileRecords(patient._id);
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      lastname: '',
      birthdate: '',
      gender: '',
      height: '',
      weight: '',
      bloodType: '',
      diseases: [],
      emergencyContacts: []
    },
    onSubmit: values => {
      onUpdatePatient(selectedPatient._id, values, (patient) => {
        setOpenEditModal(false);
        setTimeout(() => {
          setSelectedPatient(patient);
          setNewValues(patient);
        }, 200);
      });
    }
  })

  useEffect(() => {
    if (selectedPatient) {
      setNewValues(selectedPatient);
    }
  }, [selectedPatient]);

  const setNewValues = (values) => {
    formik.setValues(values);
  }

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <LoggedInRoute>
        <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)} >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            backgroundColor: 'white',
            borderRadius: '15px',
            paddingX: 10,
            paddingY: 5,
          }}>
            <Typography variant='h5'>Editar información de {selectedPatient?.name}</Typography>
            <PatientPhoto patient={selectedPatient} />
            <PatientForm formik={formik} isEditting={true} />
          </Box>
        </Dialog>
        <Box sx={{
          background: 'linear-gradient(180deg, rgba(225,210,189,1) 28%, rgba(240,239,235,1) 28%)',
          minHeight: 'calc(100vh - 67px)',
          display: 'flex',
          paddingY: {xs: 2, md: 10},
          paddingX: {xs: 4, md: 30},
          boxSizing: 'border-box',
        }} >
          {/* patients list */}
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            width: '100%',
            boxSizing: 'border-box',
            marginX: 'auto',
            backgroundColor: 'white',
            borderRadius: '15px',
            paddingX: {xs: 2, md:10},
            paddingY: {xs: 3, md: 5},
          }}>
            <Typography variant='h5'>Hola, {user?.name}</Typography>
            <Typography variant="body1">Agrega perfiles de tus familiares para registrar su información o consulta el perfil dando clic sobre la foto</Typography>
            <Box 
              sx={{
                display: 'flex',
                gap: 2,
                overflowX: 'auto',
                borderBottom: '1px solid #ccc',
                paddingBottom: 4,
              }}
            >
              <Box onClick={createPatient} sx={{ '&:hover': { cursor: 'pointer' } }}>
                <CircledAvatar patient={{ name: 'Nuevo Perfil'}} />
              </Box>
              <Box onClick={() => openFolder(myPatient)}>
                <CircledAvatar patient={myPatient} selected={selectedPatient?._id === myPatient?._id} />
              </Box>
              {patients.map(patient => {
                if (patient._id === myPatient?._id) return null;
                return (
                  <Box key={patient._id} onClick={() => openFolder(patient)} sx={{ '&:hover': { cursor: 'pointer' } }}>
                    <CircledAvatar patient={patient} selected={selectedPatient?._id === patient?._id} />
                  </Box>
                )
              })}
            </Box>
            {/* selected patient tabs */}
            {selectedPatient && (
              <PatientTabs patient={selectedPatient} />
            )}
          </Box>
        </Box>
      </LoggedInRoute>
    </motion.div>
  );
}

export default DashboardPage;
