import { useNavigate, useParams } from "react-router-dom";
import { usePatientStore } from "../stores/patient.store";
import { useEffect, useRef, useState } from "react";
import { Box, Select, Typography, MenuItem, Snackbar, TextField, Dialog } from "@mui/material";
import moment from "moment";
import UploadFile from "../components/upload-file";
import { AddCircleOutline } from '@mui/icons-material';

const PatientPage = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const { findPatientByName, onUpdatePatient, onUploadFile, onGetPatientFiles } = usePatientStore();
  const [patient, setPatient] = useState(null);
  const [message, setMessage] = useState(null);
  const [type, setType] = useState('success');
  const [openFile, setOpenFile] = useState(false);
  const [fileType, setFileType] = useState('Documentos de seguro');
  const debounceRef = useRef();
  const [file, setFile] = useState(null);

  useEffect(() => {
    const found = findPatientByName(name);
    if (found) {
      setPatient(found);
      onGetPatientFiles(found._id);
    } else {
      navigate('/dashboard');
    }
  }, [findPatientByName, name, navigate, onGetPatientFiles]);

  const handlePatientFieldChange = (event) => {
    const { name, value } = event.target;
    const updatedPatient = { ...patient, [name]: value };
    setPatient(updatedPatient);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      onUpdatePatient(patient._id, updatedPatient).then((response) => {
        setPatient(response);
        setMessage('Paciente actualizado');
      });
    }, 1000)
  }
  
  const onSuccessFileUploaded = () => {
    setMessage('Archivo subido');
    setType('success');

    setOpenFile(false);
  }

  const handleFile = () => {
    const form = new FormData();
    form.append('file', file);
    form.append('title', file.name);
    form.append('patient', patient._id);
    form.append('type', fileType);

  
    onUploadFile(form, onSuccessFileUploaded);
  }

  if (!patient) return null;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1rem',
      gap: '1rem'
    }}>
      <Snackbar
        open={!!message}
        autoHideDuration={3000}
        onClose={() => setMessage(null)}
        message={message}
        severity={type}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <Dialog
        open={openFile}
        onClose={() => setOpenFile(false)}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '2rem'
        }}>
          <Typography variant="h4">Subir archivo</Typography>
          <Select value={fileType} onChange={(e) => setFileType(e.target.value)} label="Tipo de archivo">
            <MenuItem value="Documentos de seguro">Documentos de seguro</MenuItem>
            <MenuItem value="Estudios o laboratorios">Estudios o laboratorios</MenuItem>
          </Select>
          <UploadFile file={file} setFile={setFile} />
          <Box sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <button onClick={handleFile}>Subir archivo</button>
          </Box>
        </Box>
      </Dialog>
      <Typography variant="h3">{patient.name} {patient.lastname}</Typography>
      <Select 
        name="gender"
        value={patient.gender}
        onChange={handlePatientFieldChange}
        label="Género"
      >
        <MenuItem
          value={'male'}
          >Hombre</MenuItem>
        <MenuItem
          value={'female'}
          >Mujer</MenuItem>
                  <MenuItem
          value={'undefined'}
          >No especificado</MenuItem>
      </Select>
      <Typography variant="h5">{moment().diff(patient.birthdate, 'years', false)} años</Typography>
      <TextField value={patient.bloodType} name="bloodType" onChange={handlePatientFieldChange} label="Tipo de sangre" />
      <TextField value={patient.height} name="height" onChange={handlePatientFieldChange} label="Estatura" />
      <TextField value={patient.weight} name="weight" onChange={handlePatientFieldChange} label="Peso" />
      <Typography variant="h5" onClick={() => setOpenFile(true)} sx={{
        cursor: 'pointer',
        display: 'flex',
        gap: '1rem',
        alignItems: 'center'
      
      }} >
        <AddCircleOutline  />
        Subir archivo
      </Typography>
    </Box>
  );
}

export default PatientPage;
