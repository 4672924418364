import { Box, Button, Dialog, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useEstudiosStore } from "../../stores/estudios.store"
import { useEstudiosState } from "../../hooks/useEstudiosState"
import {
  GridRowModes,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import * as yup from 'yup';

const EstudiosView = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEstudio, setSelectedEstudio] = useState(null);
  const {
    onFetchEstudios,
    fetched,
    estudios,
    onCreateEstudio,
    onDeleteEstudio,
    onUpdateEstudio
  } = useEstudiosStore();

  const { rowModesModel, setRowModesModel, handleCancelClick, handleDeleteClick } = useEstudiosState(estudios);

  const deleteEstudio = (id) => {
    onDeleteEstudio(id);
    handleDeleteClick(id);
  }

  const validateSchema = yup.object().shape({
    name: yup.string().required('El nombre es requerido'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      minAge: '',
      maxAge: '',
      gender: '',
    },
    validationSchema: validateSchema,
    onSubmit: values => {
      if (selectedEstudio) {
        onUpdateEstudio(selectedEstudio._id, values);
        setRowModesModel({ ...rowModesModel, [selectedEstudio._id]: { mode: GridRowModes.View } });
        setSelectedEstudio(null);
        formik.resetForm();
        setOpenModal(false);
      } else {
        onCreateEstudio(values, () => {
          setOpenModal(false);
          formik.resetForm();
        });
      }
    }
  })

  const handleSaveIcon = (row) => () => {

    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.View } });

  }

  const estudiosColumns = [
  { field: 'name', headerName: 'Nombre', width: 180 },
  {
    field: 'description',
    headerName: 'Descripción',
    width: 220,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'minAge',
    headerName: 'Edad mínima',
    type: 'number',
    width: 180,
    align: 'left',
    headerAlign: 'left',
  },

  {
    field: 'maxAge',
    headerName: 'Edad máxima',
    type: 'number',
    width: 180,
    align: 'left',
    headerAlign: 'left',
  },

  {
    field: 'gender',
    headerName: 'Género que aplica',
    width: 220,
    type: 'singleSelect',
    valueOptions: ['Hombre', 'Mujer', 'Ambos'],
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    editable: true,
    getActions: (row) => {
      const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: 'primary.main',
            }}
            onClick={handleSaveIcon(row)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(row.id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={editOnDoubleClick(row)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteEstudio(row.id)}
          color="inherit"
        />,
      ];
    },
  },
  ];

  const editOnDoubleClick = (params) => () => {
    formik.setValues(params.row);
    setSelectedEstudio(estudios.find(estudio => estudio._id === params.id));
    setOpenModal(true);
  }

  useEffect(() => {
    if (!fetched) {
      onFetchEstudios();
    }
  }, [fetched, onFetchEstudios]);

  const onAddEstudio = () => {
    setOpenModal(true);
  }

  const closeModal = () => {
    setOpenModal(false);
    formik.resetForm();
  }
  
  return(
    <Box sx={{}}>
      <Dialog open={openModal} onClose={closeModal} maxWidth="lg">
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
            <Typography variant='h5'>{selectedEstudio !== null ? 'Actualizar' : 'Agregar'} estudio</Typography>
            <TextField
              label="Nombre"
              fullWidth
              {...formik.getFieldProps('name')}
            />
            <TextField
              label="Descripción"
              fullWidth
              {...formik.getFieldProps('description')}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <TextField
                label="Edad mínima"
                fullWidth
                type="number"
                {...formik.getFieldProps('minAge')}
              />
              <TextField
                label="Edad máxima"
                fullWidth
                type="number"
                {...formik.getFieldProps('maxAge')}
              />
            </Box>
            <Select
              label="Género que aplica"
              fullWidth
              {...formik.getFieldProps('gender')}
            >
              <MenuItem value="Hombre">Hombre</MenuItem>
              <MenuItem value="Mujer">Mujer</MenuItem>
              <MenuItem value="Ambos">Ambos</MenuItem>
            </Select>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!(formik.dirty && formik.isValid)} >{selectedEstudio !== null ? 'Actualizar' : 'Agregar'}</Button>
          </Box>
        </form>
      </Dialog>
      <Button sx={{ marginLeft: 'auto', display: 'flex'}} color="primary" startIcon={<AddIcon />} onClick={onAddEstudio}>
        Agregar estudio
      </Button>
      <DataGrid
        rows={estudios}
        columns={estudiosColumns}
        // editMode="row"
        getRowId={(row) => row._id}
        onCellDoubleClick={editOnDoubleClick}
        />
    </Box>
  )
}

export default EstudiosView;
