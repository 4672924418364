import axios from 'axios';
import { url } from '../utils/url';

export const getPatients = async () => {
  try {
    const response = await axios.get(`${url}/api/patient/all`, { withCredentials: true });
    const patients = response.data;
    for (const patient of patients) {
      const url = await getPresignedUrl(patient.avatar);
      response.data.avatar = url;
    }
    return patients;
  } catch (error) {
    return error.response.data;
  }
}

export const createPatient = async (data) => {
  try {
    const response = await axios.post(`${url}/api/patient/create`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updatePatient = async (id, data) => {
  try {
    const response = await axios.put(`${url}/api/patient/update/${id}`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const uploadFile = async (data) => {
  const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    }
  }
  try {
    const response = await axios.post(`${url}/api/patient/file`, data, config);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getAllFiles = async () => {
  try {
    const response = await axios.get(`${url}/api/patient/contents`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getFileRecords = async (patientId) => {
  try {
    const response = await axios.get(`${url}/api/patient/files/${patientId}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getPresignedUrl = async (key) => {
  try {
    const response = await axios.post(`${url}/api/patient/patient-file`, {
      key: encodeURIComponent(key)
    }, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getKeysByPatient = async (patientId) => {
  try {
    const response = await axios.get(`${url}/api/patient/files/${patientId}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updateDocument = async (id, data) => {
  try {
    const response = await axios.put(`${url}/api/patient/file/${id}`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getPatientCampaigns = async (patientId) => {
  try {
    const response = await axios.get(`${url}/api/campaign/bypatient/${patientId}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updateCampaign = async (id, data) => {
  try {
    const response = await axios.put(`${url}/api/campaign/${id}`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}