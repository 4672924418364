import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Chip, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { ContactEmergency } from '@mui/icons-material';
import moment from 'moment';
import { usePatientStore } from '../stores/patient.store';

const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
const diseases = ['Diabetes', 'Hipertensión', 'Cáncer', 'VIH', 'Otras'];

const PatientForm = ({ formik, isEditting }) => {

  const [selectedDate, setSelectedDate] = useState(formik.values.birthdate ? moment(formik.values.birthdate) : undefined);
  const [selectedDisease, setSelectedDisease] = useState(undefined);
  const [newDisease, setNewDisease] = useState('');
  const [showDiseaseField, setShowDiseaseField] = useState(false);
  const { selectedPatient } = usePatientStore();
  const handleDateChange = (date) => {
    const d = date.format('YYYY-MM-DD');
    formik.setFieldValue('birthdate', d);
    setSelectedDate(date);
  }

  const handleDiseases = (event) => {
    const value = event.target.value;
    setSelectedDisease(value);
    const diseases = formik.values.diseases || [];
    if (value === 'Otras') {
      setShowDiseaseField(true);
      return;
    }
    if (diseases.indexOf(value) === -1) {
      diseases.push(value);
    } else {
      diseases.splice(diseases.indexOf(value), 1);
    }
    formik.setFieldValue('diseases', diseases);
    setSelectedDisease(undefined);
  }

  const handleAddDisease = () => {

    setShowDiseaseField(!showDiseaseField);
  }


  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'},
          gap: 3,
          marginBottom: 3,
      }}>
        <TextField
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Nombre(s)"
          fullWidth
        />
        <TextField
          name="lastname"
          value={formik.values.lastname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label="Apellidos"
          fullWidth
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: {xs: 'column', md: 'row'},
        gap: 3,
        marginBottom: 3,
      }}>
        <DatePicker
          name="birthdate"
          value={selectedDate}
          onChange={handleDateChange}
          fullWidth
          label='Fecha de nacimiento'
          sx={{ width: '100%', paddingX: 0}}
        />
        <FormControl fullWidth>
          <InputLabel>Género al nacer</InputLabel>
          <Select value={formik.values.gender} onChange={formik.handleChange} fullWidth name="gender" >
            <MenuItem value="unknown">No especificado</MenuItem>
            <MenuItem value="male">Hombre</MenuItem>
            <MenuItem value="female">Mujer</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: {xs: 'column', md: 'row'},
        gap: 3,
        marginBottom: 3,
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'},
          gap: 3,
          width: '100%',
        }}>
          <TextField
            name="height"
            value={formik.values.height}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="Estatura (cm)"
            fullWidth
          />
          <TextField
            name="weight"
            value={formik.values.weight}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="Peso (kg)"
            fullWidth
          />
        </Box>
        <FormControl fullWidth>
          <InputLabel>Tipo de sangre (Opcional)</InputLabel>
          <Select value={formik.values.bloodType} onChange={formik.handleChange} fullWidth name="bloodType" >
            <MenuItem value="undefined"></MenuItem>
            {bloodTypes.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'},
          gap: 3,
          marginBottom: 3,
      }}>
        <FormControl fullWidth>
          <InputLabel>Enfermedades (Opcional)</InputLabel>
          <Select value={selectedDisease} onChange={handleDiseases} fullWidth name="diseases" >
            <MenuItem value="undefined"></MenuItem>
            {diseases.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {showDiseaseField && (<TextField value={newDisease} onChange={(e) => setNewDisease(e.target.value)} name="diseases" label="Nueva enfermedad" fullWidth InputProps={{
          endAdornment: <IconButton onClick={() => {
            const diseases = formik.values.diseases || [];
            diseases.push(newDisease);
            formik.setFieldValue('diseases', diseases);
            setNewDisease('');
            setShowDiseaseField(false);
          }} >+</IconButton>
        }} />)}
      </Box>
      {formik.values.diseases && formik.values.diseases.map((disease, index) => (
        <Chip
          key={index}
          label={disease}
          onDelete={() => {
            const diseases = formik.values.diseases || [];
            diseases.splice(diseases.indexOf(disease), 1);
            formik.setFieldValue('diseases', diseases);
          }}
        />
      ))}
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        gap: 2,
        marginTop: 4
      }}>
        <ContactEmergency sx={{ color: 'primary.main' }} />
        <Typography
          variant="body1"
          sx={{
            fontWeight: 900,
            fontFamily: 'gotham',
            marginTop: '5px'  
          }} >Contactos de emergencia</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 3,
      }}>
        {formik.values.emergencyContacts && formik.values.emergencyContacts.map((contact, index) => (
          <Box key={index} sx={{
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row'},
            gap: 3,
            marginBottom: 3,
          }}>
            <TextField
              key={`name-${index}`}
              name={`emergencyContacts[${index}].name`}
              value={contact.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Nombre del contacto"
              fullWidth
            />
            <TextField
              key={`phone-${index}`}
              name={`emergencyContacts[${index}].phone`}
              value={contact.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Teléfono del contacto"
              fullWidth
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, alignItems: 'center'}} onClick={() => formik.setFieldValue('emergencyContacts', [...formik.values.emergencyContacts, { name: '', phone: '' }])}>
        <Typography variant="body2" sx={{ marginBottom: 3, color: '#1C4CDF', fontSize: '18px' }} > + Agregar otro contacto</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
        <Button type="submit" variant='contained' disabled={!(formik.dirty && formik.isValid)} >{isEditting ? 'Guardar cambios' : 'Crear paciente'}</Button>
      </Box>
    </form>

  )
}

export default PatientForm;
