import React, { useEffect, useRef, useState } from 'react';
import { Box, Tabs, Tab, Typography, TextField, Button, Dialog, Link, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem } from '@mui/material';
import { DescriptionOutlined, ExpandMore, Settings } from '@mui/icons-material';
import moment from 'moment';
import { usePatientStore } from '../../stores/patient.store';
import UploadFile from '../upload-file';
import { BASE_BROWN } from '../../utils/constants';
import { useUserStore } from '../../stores/user.store';
import UsersAdminComponent from './users.admin.component';
import PatientGeneralInfo from './patient-general-info';
import { useEstudiosStore } from '../../stores/estudios.store';
import EstudiosView from './estudios.view';
import SuscriptionProducts from './suscription-products';

const FileTypes = {
  DocumentosDeSeguro: 'documentos-de-seguro',
  EstudiosOLaboratorios: 'estudios-o-laboratorios'
};

const PatientTabs = ({ patient }) => {
  const [value, setValue] = useState(0);
  const [adminValue, setAdminValue] = useState(0);
  const { selectedPatientFiles, onUploadFile, onDocumentUpdate } = usePatientStore();
  const { onFetchEstudios, fetched } = useEstudiosStore();
  const { user } = useUserStore();
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [title, setTitle] = useState('');
  const [openShowFile, setOpenShowFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [anottation, setAnottation] = useState('');
  const [query, setQuery] = useState('');

  const closeDialog = () => {
    setOpenUploadFile(false);
    setFile(null);
  }


  const handleFileChange = (e, fileType) => {
    e.preventDefault();
    e.stopPropagation();
    let selected = e.target.files[0];
    // const types = ['image/png', 'image/jpeg', 'file/pdf', 'file/docx', 'file/doc', 'image/jpg'];

    if (selected) {
        setError('');
    } else {
        setFile(null);
        setError('Selecciona un archivo válido (png, jpg, pdf, docx, doc)');
    }
  };

  const handleSearch = () => {
    if (!query) return selectedPatientFiles;

    const filtered = selectedPatientFiles.filter(file => file.type !== 'avatar' && file.title.toLowerCase().includes(query.toLowerCase()));
    return filtered;
  }

  const uploadFile = (type) => {
    console.log('uploading file');
    console.log('file', file);
    const form = new FormData();
    form.append('file', file);
    form.append('patient', patient._id);
    form.append('type', type);
    form.append('title', title);
    console.log('form', form);
    onUploadFile(form, () => {
      setFile(null);
      setTitle('');
      setOpenUploadFile(false);
    });
  }

  const openFile = (file) => {
    setOpenShowFile(true);
    setSelectedFile(file);
  }


  const contents = [
    { value:  (
      <PatientGeneralInfo patient={patient} />
      )
    },
    {
      value: (
        <Box sx={{
          paddingY: 4
        }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          gap: 2,
          marginBottom: 5
        }}>
          <DescriptionOutlined sx={{ color: 'primary.main' }} />
          <Typography
            variant="body1"
            sx={{
              fontWeight: 900,
              fontFamily: 'gotham',
              marginTop: '5px'
            }} >Documentos de seguros</Typography>
        </Box>
        <Dialog open={openUploadFile} onClose={closeDialog} >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            backgroundColor: 'white',
            borderRadius: '15px',
            paddingX: 10,
            paddingY: 5,
          }}>
            <Typography variant='h5'>Subir documento</Typography>
            <UploadFile file={file} setFile={setFile} />
            {file && (
              <TextField label="Título" placeholder="Nombra tu documento para futuras búsquedas" variant="outlined" sx={{ marginBottom: 3 }} value={title} onChange={e => setTitle(e.target.value)} />
            )}
            {error && <Typography>{error}</Typography>}
            <Button variant="contained" color="primary" onClick={() => uploadFile(FileTypes.DocumentosDeSeguro)} disabled={!file || title.length < 1} >Guardar documento</Button>
          </Box>
        </Dialog>
        <input type="file" style={{ display: 'none'}} ref={fileRef} onChange={(e) => handleFileChange(e, FileTypes.DocumentosDeSeguro)} />
        <Box sx={{
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'},
          justifyContent: 'space-between',
        }}>
          <TextField label="buscar" placeholder='Nombre del documento...' sx={{ width: { xs: 'auto', md: '350px'}, marginBottom: { xs: '24px', md: 'auto' }}} value={query} onChange={e => setQuery(e.target.value)} />
          <Button variant="contained" color="primary" onClick={() => setOpenUploadFile(true)} >Subir documento</Button>
        </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row'},
            gap: 4,
            width: '100%',
            flexWrap: 'wrap'
          }}>
            {handleSearch().filter(file => file.type === FileTypes.DocumentosDeSeguro).map(file => (
              <Box
                key={file._id}
                onClick={() => openFile(file)}
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: 200,
                borderRadius: '8px',
                border: '0.5px solid #E1D2BD',
                marginTop: 5,
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                minWidth: { xs: '100%', md: '200px'},
                }}>
                <DescriptionOutlined sx={{  fontSize: '25px', color: BASE_BROWN}} />
                <Typography key={file._id}>{file.title}</Typography>
                <Typography variant="body2" sx={{ color: 'gray', fontSize: '12px'}}>{moment(file.createdAt).format('DD/MM/YYYY')}</Typography>
              </Box>
            ))}
            {selectedPatientFiles.filter(file => file.type === FileTypes.DocumentosDeSeguro).length < 1 && (
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 200,
                borderRadius: '8px',
                border: '0.5px solid #E1D2BD',
                marginTop: 5,
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                flexDirection: 'column',
                width: '100%'
              }}>
                <Typography variant="body1" sx={{ textAlign: 'center'}}>Aquí podrás visualizar los documentos que cargues</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )
    },
    {
      value: (
        <Box sx={{
          paddingY: 4
        }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          gap: 2,
          marginBottom: 5
        }}>
          <DescriptionOutlined sx={{ color: 'primary.main' }} />
          <Typography
            variant="body1"
            sx={{
              fontWeight: 900,
              fontFamily: 'gotham',
              marginTop: '5px'
            }} >Exámenes y laboratoriales</Typography>
        </Box>
        <Dialog open={openUploadFile} onClose={closeDialog} >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            backgroundColor: 'white',
            borderRadius: '15px',
            paddingX: 10,
            paddingY: 5,
          }}>
            <Typography variant='h5'>Subir documento</Typography>
            <UploadFile file={file} setFile={setFile} />
            {file && (
              <TextField label="Título" placeholder="Nombra tu documento para futuras búsquedas" variant="outlined" sx={{ marginBottom: 3 }} value={title} onChange={e => setTitle(e.target.value)} />
            )}
            {error && <Typography>{error}</Typography>}
            <Button variant="contained" color="primary" onClick={() => uploadFile(FileTypes.EstudiosOLaboratorios)} disabled={!file || title.length < 1} >Guardar documento</Button>
          </Box>
        </Dialog>
        <input type="file" style={{ display: 'none'}} ref={fileRef} onChange={(e) => handleFileChange(e, FileTypes.EstudiosOLaboratorios)} />
        <Box sx={{
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'},
          justifyContent: 'space-between',
        }}>
          <TextField label="buscar" placeholder='Nombre del documento...' sx={{ width: { xs: 'auto', md: '350px'}, marginBottom: { xs: '24px', md: 'auto' }}} value={query} onChange={e => setQuery(e.target.value)} />
          <Button variant="contained" color="primary" onClick={() => setOpenUploadFile(true)} >Subir documento</Button>
        </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: {xs: 'column', md: 'row'},
            gap: 4,
            width: '100%',
            flexWrap: 'wrap'
          }}>
            {handleSearch().filter(file => file.type === FileTypes.EstudiosOLaboratorios).map(file => (
              <Box
                key={file._id}
                onClick={() => openFile(file)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: 200,
                  borderRadius: '8px',
                  border: '0.5px solid #E1D2BD',
                  marginTop: 5,
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                  minWidth: { xs: '100%', md: '200px'},
                }}>
                <DescriptionOutlined sx={{  fontSize: '25px', color: BASE_BROWN}} />
                <Typography key={file._id}>{file.title}</Typography>
                <Typography variant="body2" sx={{ color: 'gray', fontSize: '12px'}}>{moment(file.createdAt).format('DD/MM/YYYY')}</Typography>
              </Box>
            ))}
            {selectedPatientFiles.filter(file => file.type === FileTypes.EstudiosOLaboratorios).length < 1 && (
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 200,
                borderRadius: '8px',
                border: '0.5px solid #E1D2BD',
                marginTop: 5,
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                flexDirection: 'column',
                width: '100%'
              }}>
                <Typography variant="body1" sx={{ textAlign: 'center'}}>Aquí podrás visualizar los documentos que cargues</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )
    },
  ]


  useEffect(() => {
    if (user.role === 'superadmin' && !fetched) {
      onFetchEstudios();
    }
  }, [user.role, fetched, onFetchEstudios]);


  if (user.role === 'superadmin') {

    const adminContents = [
      {
        value: (
          <UsersAdminComponent />
        )
      },
      {
        value: (
          <EstudiosView />
        )
      },
      {
        value: <SuscriptionProducts />
      },
      {
        value: null
      }
    ]
    contents.push({
      value: (
        <Box sx={{
          paddingY: 4
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            gap: 2,
            marginBottom: 3
          }}>
            <Settings sx={{ color: 'primary.main' }} />
            <Typography
              variant="body1"
              sx={{
                fontWeight: 900,
                fontFamily: 'gotham',
                marginTop: '5px'
              }} >Administración</Typography>
          </Box>
          <Tabs variant='scrollable' value={adminValue} onChange={(e, newValue) => setAdminValue(newValue)} allowScrollButtonsMobile >
            <Tab label="Usuarios" />
            <Tab label="Estudios" />
            <Tab label="Productos para suscripciones" />
          </Tabs>
          {adminContents[adminValue].value}
        </Box>
      )
    })
  }

  const storeAnottation = () => {
    const updated = { ...selectedFile };

    updated.anottations.push(anottation);
    onDocumentUpdate(selectedFile._id, updated)
    setAnottation('');
  }

  return (
    <Box >
      <Dialog open={openShowFile} onClose={() => setOpenShowFile(false)} maxWidth="lg" >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          backgroundColor: 'white',
          borderRadius: '15px',
          paddingX: 10,
          paddingY: 5,
          minHeight: '75vh',
          maxHeight: '80vh',
        }}>
          <Typography variant='h4' align='center'>{selectedFile?.title}</Typography>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 5,
          }}>
            {selectedFile?.file.includes('.pdf') ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                // justifyContent: 'center',
                alignItems: 'center',
              }}>
                <DescriptionOutlined sx={{  fontSize: '100px', color: BASE_BROWN}} />
                <Link href={selectedFile?.url} target="_blank" sx={{ textDecoration: 'none', color: BASE_BROWN}} >Ver archivo</Link>
                <Typography variant="body2" sx={{ color: 'gray', fontSize: '12px'}}>{ moment(selectedFile?.createdAt).format('DD/MM/YYYY') } </Typography>
              </Box>
            ) : (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
                <img src={selectedFile?.url} alt={selectedFile?.title} style={{ width: '100%', height: 'auto', borderRadius: '8px'}} />
                <Typography variant="body2" sx={{ color: 'gray', fontSize: '12px'}}>{ moment(selectedFile?.createdAt).format('DD/MM/YYYY') } </Typography>
              </Box>
            )}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
              {selectedFile?.anottations.length > 0 && (
                <Accordion sx={{ marginBottom: 3}} >
                  <AccordionSummary expandIcon={<ExpandMore />} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}} >
                    <Typography variant="h6" sx={{ marginTop: '10px', display: 'flex'}} >Ver las anotaciones de este archivo</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {selectedFile?.anottations.map(anottation => (
                      <Typography key={anottation} variant="body1" sx={{ marginBottom: 1}}>{anottation}</Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
              <TextField label="Nueva anotación" multiline rows={3} variant="outlined" sx={{ width: '100%', marginBottom: 3}} value={anottation} onChange={(e) => setAnottation(e.target.value)} />
              <Button variant="contained" color="primary" onClick={storeAnottation} >Guardar anotación</Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Tabs
        variant="scrollable"
        allowScrollButtonsMobile
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        aria-label="basic tabs example"
      >
        <Tab label="General" />
        <Tab label="Documentos de seguro" />
        <Tab label="Exámenes/laboratorios" />
        {user.role === 'superadmin' && (<Tab label="Administración" />)}
      </Tabs>
      {contents[value].value}
    </Box>
  );
}

export default PatientTabs;
