import React from 'react';
import { motion } from 'framer-motion';
import { TextField, Box, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { loginVariants } from '../utils/animations';
import { useUserStore } from '../stores/user.store';
import logo from '../assets/images/logo.png';

const Login = () => {
    const { onLogin } = useUserStore();
    const validationSchema = Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().required('Required')
    });
  
    const formik = useFormik({
      initialValues: {
        email: '',
        password: ''
      },
      validationSchema: validationSchema,
      onSubmit: values => {
        onLogin(values);
      }
    });

    const onRemember = (e) => {
      if (e.target.checked) {
        localStorage.setItem('mail', formik.values.email);
        localStorage.setItem('password', formik.values.password);
      } else {
        localStorage.removeItem('mail');
        localStorage.removeItem('password');
      }
    }
  
      return (
        <Box sx={{
          width: '100%',
        }}>
          <motion.div
            variants={loginVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <img src={logo} alt="logo" style={{ display: 'block', margin: 'auto', marginBottom: '20px', height: '250px' }} />
            <Typography variant="h6" sx={{
              marginBottom: 2
            }} >Iniciar sesión</Typography>
            <Typography variant="body1" sx={{
              marginBottom: 10
            }}>Bienvenido de nuevo, ingrese su correo y contraseña para acceder a su cuenta.</Typography>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '0 auto',
                  marginTop: '20px',
                  gap: '20px'
              
              }} >
                  <TextField name="email" label="Correo electrónico" type="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  <Typography variant="body2" color="error">{formik.touched.email && formik.errors.email}</Typography>
                  <TextField name="password" label="Contraseña" type="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  <Typography variant="body2" color="error">{formik.touched.password && formik.errors.password}</Typography>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}>
                      <FormControlLabel control={<Checkbox onChange={onRemember} />} label="Recuérdame" />
                      <Button variant="text" color="primary">¿Olvidó su contraseña?</Button>
                  </Box>
                  <Box sx={{
                    marginBottom: 4
                  }}>
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={!(formik.dirty && formik.isValid)} >Submit</Button>
                  </Box>
              </Box>
            </form>
          </motion.div>
        </Box>
      );
}

export default Login;
