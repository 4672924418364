import { createTheme } from "@mui/material";
import { BASE_BLUE, BASE_BROWN } from "./utils/constants";

const theme = createTheme({
  palette: {
    primary: {
      main: BASE_BROWN
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'gotham'
        },
        h2: {
          fontFamily: 'gotham'
        },
        h3: {
          fontFamily: 'gotham'
        },
        h4: {

          fontFamily: 'gotham'
        },
        h5: {
          fontFamily: 'gotham'
        },
        h6: {
          fontFamily: 'gotham'
        },
        body1: {
          fontFamily: 'tahoma'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'tahoma'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'tahoma',
        },
        contained: {
          boxShadow: 'none',
          backgroundColor: BASE_BLUE,
          fontWeight: 900,
          fontFamily: 'tahoma-bold',
          padding: '10px 20px',
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'tahoma',
          fontWeight: 600,
          color: 'white',
          '&:hover': {
            textDecoration: 'underline'
          },
          '&:active': {
            color: 'white',
            textDecoration: 'underline',
            fontWeight: 900
          }
        }
      }
    }
  }
})

export default theme;
