import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/navbar';
import AnimatedRoutes from './components/animated-routes';
import { useEffect } from 'react';
import { useUserStore } from './stores/user.store';
import Loading from './components/loading.component';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment';
import { ThemeProvider } from '@mui/material';
import theme from './theme';

function App() {
  const { onVerify } = useUserStore();
  
  moment.lang('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
  }
  );
  
  useEffect(() => {
    onVerify();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <Navbar />
          <AnimatedRoutes />
        </Router>
        <Loading />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
