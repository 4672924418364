import axios from 'axios';
import { url } from '../utils/url';


export const register = async (data) => {
  try {
    const response = await axios.post(`${url}/api/auth/register` , data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const login = async (data) => {
  try {
    const response = await axios.post(`${url}/api/auth/login`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const logout = async () => {
  try {
    const response = await axios.post(`${url}/api/auth/logout`, null, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const verify = async () => {
  try {
    const response = await axios.get(`${url}/api/auth/verify`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response?.data;
  }
}

export const findAllUsers = async () => {
  try {
    const response = await axios.get(`${url}/api/user/all`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updateUser = async (data) => {
  try {
    const response = await axios.put(`${url}/api/user/update`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}