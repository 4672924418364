import React from 'react';
import { motion } from 'framer-motion';
import { Box, Typography, Link } from '@mui/material';
import { BASE_BLUE } from '../utils/constants';
import logo from '../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return(
    <Box sx={{
      backgroundColor: BASE_BLUE,
      color: 'white',
      paddingX: 5,
      paddingY: 3,
    }}>
      <Box sx={{
        borderBottom: '1px solid white',
        display: 'flex',
        gap: 4,
        flexDirection: {xs: 'column', md: 'row'},
        paddingY: 2,
        justifyContent: {xs: 'center', md: 'space-around'}
      }}>
        <Box sx={{}}>
          <img src={logo} alt="logo" style={{ height: '100px'}} />
        </Box>
        <Box sx={{}}>
          <Typography variant="h6" sx={{ fontWeight: 700 }} >Contáctanos</Typography>
          <Typography variant="body1">Teléfono: 1234567890</Typography>
          <Typography variant="body1">Correo: </Typography>
        </Box>
        <Box sx={{}}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>Síguenos</Typography>
          <Typography variant="body1">Facebook</Typography>
          <Typography variant="body1">Instagram</Typography>
          <Typography variant="body1">Twitter</Typography>
        </Box>
        <Box sx={{}}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>Legales</Typography>
          <Typography variant="body1">Política de privacidad</Typography>
          <Typography variant="body1" onClick={() => navigate('terminos')} >Términos y condiciones</Typography>
        </Box>

      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 2,
        paddingY: 2,
      }}>
        <Typography variant="body1" sx={{ color: 'white' }}>Desarrollado por <Link href="https://www.instagram.com/colmenarestech" target="_blank" rel="noreferrer" sx={{ color: 'white'}} >@Colmenares_tech</Link></Typography>
      </Box>
    </Box>
  );
}

export default Footer;
