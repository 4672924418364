import React from 'react';
import { motion } from 'framer-motion';
import { pageVariants } from '../utils/animations';
import { Box, Button, Typography, useMediaQuery, Icon } from '@mui/material';
import { BASE_BEIGE, BASE_BROWN } from '../utils/constants';
import landing from '../assets/images/landing.svg';
import { calculateHeight, calculateWidth } from '../utils/sizer';
import { DescriptionOutlined, BackupOutlined } from '@mui/icons-material';
import doctora from '../assets/images/doctora.svg';
import Footer from '../components/footer';
import { useNavigate } from 'react-router-dom';

import tubes from '../assets/icons/tubes.svg';

const LandingPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Box sx={{
        backgroundColor: 'white',
        }}>
        <Box sx={{
          alignItems: 'center',
          justifyContent: 'center',
          padding: 4,
          flexDirection: 'column',
          gap: 2,
          backgroundColor: BASE_BEIGE,
          display: {xs: 'none', md: 'flex'},

        }}>
          <Typography variant="h3" align="center" sx={{
            fontWeight: 700,
            maxWidth: {xs: '90%', md: '70%'},
            
          }} >Diagnóstico más preciso con un historial médico completo al alcance de tu mano</Typography>
          <Typography variant="h5" align="center" sx={{
            fontWeight: 400,
            maxWidth: {xs: '90%', md: '70%'},
          }} >Organiza y accede a todos tus registros médicos y los de tu familia de manera rápida y segura</Typography>
          <Button variant="contained" sx={{ borderRadius: '24px'}} onClick={() => navigate('login')} >Regístrate para iniciar</Button>
        </Box>
        <Box sx={{
        background: {xs: BASE_BEIGE, md: `linear-gradient(180deg, ${BASE_BEIGE} 50%, #FFF 50%)`},
        minHeight: 'calc(100vh - 67px)',
        display: 'flex',
        paddingY: {xs: 2, md: 10},
        width: '100%',
        boxSizing: 'border-box',
        position: 'relative',
        flexDirection: {xs: 'column', md: 'inherit'},
        }}>
          <Box sx={{
            backgroundImage: `url(${landing})`,
            height: calculateHeight(722),
            width: { xs: '100%', md: calculateWidth(1083)},
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
            borderRadius: '15px',
            boxSizing: 'border-box',
            border: `15px solid #E0D1BDB2`,
            margin: 'auto',
            marginBottom: {xs: 3, md: 0},
            display: 'flex',
          }}>
            <Box sx={{
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              padding: 4,
              flexDirection: 'column',
              gap: 2,
              // backgroundColor: BASE_BEIGE,
              backdropFilter: 'blur(10px)',
              display: {xs: 'flex', md: 'none'},

            }}>

                <Typography variant="h5" align="center" sx={{
                  fontWeight: 400,
                  maxWidth: {xs: '90%', md: '70%'},
                }} >Organiza y accede a todos tus registros médicos y los de tu familia de manera rápida y segura</Typography>
                <Button variant="contained" sx={{ borderRadius: '24px'}} onClick={() => navigate('login')} >Regístrate para iniciar</Button>
              </Box>

            </Box>
            <Box sx={{
              position: {xs: 'static' ,  md: 'absolute'},
              top: '50%',
              left: '10%',
              transform: { xs: 'none', md: 'translate(-50%, -50%)'},
              padding: 4,
              borderRadius: '65px',
              backgroundColor: '#AECDEFCC',
              backdropFilter: 'blur(10px)',
              textAlign: 'center',
              maxWidth: { xs: '100%', md: '300px'},
              marginX: {xs: '5%', md: 0},
              marginBottom: {xs: 3, md: 0},
            }}>
              <Typography variant="body1">Comparte fácilmente tu historial con médicos</Typography>
            </Box>
            <Box sx={{
              position: {xs: 'static' ,  md: 'absolute'},
              top: '52%',
              right: '0',
              transform: { xs: 'none', md: 'translate(-20%, -50%)'},
              padding: 4,
              borderRadius: '65px',
              backgroundColor: '#AECDEFCC',
              backdropFilter: 'blur(10px)',
              textAlign: 'center',
              maxWidth: { xs: '100%', md: '250px'},
              marginX: {xs: '5%', md: 0},
              marginBottom: {xs: 3, md: 0},
            }}>
              <Typography variant="body1">Obtén información de especialistas sobre tus análisis</Typography>
            </Box>
            <Box sx={{
              position: {xs: 'static' ,  md: 'absolute'},
              bottom: '7%',
              left: '50%',
              transform: { xs: 'none', md: 'translate(-50%, 50%)'},
              padding: 4,
              borderRadius: '65px',
              backgroundColor: '#AECDEFCC',
              backdropFilter: 'blur(10px)',
              textAlign: 'center',
              maxWidth: { xs: '100%', md: '200px'},
              marginX: {xs: '5%', md: 0},
            }}>
            <Typography variant="body1">Comparte con tu gente de confianza</Typography>
          </Box>
        </Box>
        {/* <img src={landing} alt="landing" style={{ width: '100%', maxWidth: '800px'}} /> */}
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 4,
          }}>
          <Typography variant="h4" sx={{ fontWeight: 700, padding: 4}}>¿Cansado de buscar entre papeles y archivos para encontrar tu historial médico?</Typography>
          <Typography variant="body1" sx={{ padding: 4, fontWeight: 700 }}>Nuestra plataforma centraliza y gestiona todos tus registros médicos, garantizando diagnósticos precisos y tratamientos óptimos. Simplifica tu vida con acceso seguro y fácil a toda tu información médica</Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          gap: 4,
          paddingX: 4,
          justifyContent: { xs: 'center', md: 'space-around'},
          marginBottom: {xs: 2, md: 6 },
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 3,
              backgroundColor: '#F1F1F1',
              borderRadius: '40px',
              padding: 6,
              boxSizing: 'border-box',
            }}>
              <DescriptionOutlined sx={{ fontSize: 100, color: BASE_BROWN }} />
              <Typography variant="h6" sx={{ fontWeight: 700 }}>Carga de documentos</Typography>
              <Typography variant="body1" sx={{}}>Ten disponibles tus documentos de seguros</Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 3,
              backgroundColor: '#F1F1F1',
              borderRadius: '40px',
              padding: 6,
              boxSizing: 'border-box',
            }}>
              <Icon sx={{ width: 100, height: 100, color: BASE_BROWN }}>
                <img src={tubes} alt="tubos" style={{ width: '100px', color: BASE_BROWN }} />
              </Icon>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>Sube informes médicos</Typography>
              <Typography variant="body1" sx={{}}>No pierdas visibilidad de tus citas médicas</Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 3,
              backgroundColor: '#F1F1F1',
              borderRadius: '40px',
              padding: 6,
              boxSizing: 'border-box',
            }}>
              <BackupOutlined sx={{ fontSize: 100, color: BASE_BROWN }} />
              <Typography variant="h6" sx={{ fontWeight: 700 }}>Recetas</Typography>
              <Typography variant="body1" sx={{}}>Tus medicamentos en la nube</Typography>
            </Box>
        </Box>
        <Box  sx={{
          padding: 4,
        }}>
          <Box sx={{
            backgroundColor: 'black',
            display: 'flex',
            borderRadius: '40px',
            paddingX: 6,
            paddingY: 8,
            color: 'white',
            position: 'relative',
            minHeight: '180px',
          }}>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 700 }}>Mantente al día con tus checkups</Typography>
              <Button variant="contained" sx={{
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '24px',
              }} onClick={() => navigate('login')} >Regístrate</Button>
            </Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              right: isMobile ? -15 : '15%',
              bottom: 0,
            }}>
              <img src={doctora} alt="doctora" style={{ width: isMobile ? '55%' : '100%', maxWidth: '300px'}} /> 
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </motion.div>
  );
}

export default LandingPage;
