import { useEffect, useState } from "react";
import { useProductosStore } from "../../stores/products.store";
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Dialog, FormControl, InputLabel, OutlinedInput, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from 'yup';

const SuscriptionProducts = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const {
    onGetProductos,
    fetched,
    productos,
    onCreateProduct,
  } = useProductosStore();

  const closeModal = () => {
    setOpenModal(false);
    setSelectedProduct(null);
  }

  const productosColumns = [
    { field: 'name', headerName: 'Nombre', width: 200 },
    { field: 'description', headerName: 'Descripción', width: 200 },
    { field: 'unit_amount', headerName: 'Precio', width: 200 },
  ];

  useEffect(() => {
    if (!fetched) {
      onGetProductos();
    }
  }, [fetched, onGetProductos]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      unit_amount: 0,
      currency: 'MXN',
    },
    validationSchema: yup.object().shape({
      name: yup.string().required('El nombre es requerido'),
      unit_amount: yup.number().required('El precio es requerido'),
    }),
    onSubmit: values => {
      const data = {
        ...values,
        unit_amount: parseInt(values.unit_amount) * 100,
      }
      onCreateProduct(data);
      formik.resetForm();
      setOpenModal(false);
    }
  });

  const onAddEstudio = () => {
    formik.resetForm();
    setOpenModal(true);
  }

  const editOnDoubleClick = (params) => () => {
    formik.setValues(params.row);
    setSelectedProduct(productos.find(estudio => estudio._id === params.id));
    setOpenModal(true);
  }


  const parseProducts = () => {
    return productos.map(producto => {
      return {
        id: producto.id,
        name: producto.name,
        description: producto.description,
        unit_amount: `$${producto.price.unit_amount / 100}`,
      }
    })
  }

  return (
    <Box sx={{}}>
      <Dialog open={openModal} onClose={closeModal} maxWidth="lg">
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}>
            <Typography variant='h5'>{selectedProduct !== null ? 'Actualizar' : 'Agregar'} estudio</Typography>
            <TextField
              label="Nombre"
              fullWidth
              {...formik.getFieldProps('name')}
            />
            <FormControl>
              <InputLabel id="currency">Precio</InputLabel>
              <OutlinedInput
                label="Precio"
                type="number"
                {...formik.getFieldProps('unit_amount')}
                startAdornment={<InputLabel htmlFor="currency">$</InputLabel>}
              />
            </FormControl>
            <TextField
              label="Descripción"
              fullWidth
              {...formik.getFieldProps('description')}
              rows={4}
            />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!(formik.dirty && formik.isValid)} >{selectedProduct !== null ? 'Actualizar' : 'Agregar'}</Button>
          </Box>
        </form>
      </Dialog>
      <Button sx={{ marginLeft: 'auto', display: 'flex'}} color="primary" startIcon={<AddIcon />} onClick={onAddEstudio}>
        Agregar producto
      </Button>
      <DataGrid
        rows={parseProducts()}
        columns={productosColumns}
        onCellDoubleClick={editOnDoubleClick}
        />
    </Box>
  )
}

export default SuscriptionProducts;
