import React, { useEffect } from 'react';
import { useUserStore } from '../stores/user.store';
import { useNavigate } from 'react-router-dom';

const LoggedInRoute = ({ children }) => {
  const navigation = useNavigate();
  const { user, loading, verified } = useUserStore();
  useEffect(() => {
    if (!loading && !user && verified) {
      navigation('/login', { replace: true });
    }
  }, [user, navigation, loading, verified]);
  
  if (loading) {
    return null;
  }

  return children;

  }

export default LoggedInRoute;