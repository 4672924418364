import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Snackbar, Box, Typography } from '@mui/material';
import Register from '../components/register.component';
import Login from '../components/login.component';
import { useUserStore } from '../stores/user.store';
import { useNavigate } from 'react-router-dom';
import { BASE_BEIGE } from '../utils/constants';
import Footer from '../components/footer';

const LoginPage = () => {
  const navigation = useNavigate();
  const [isRegistering, setIsRegistering] = useState(false);
  const { user, error } = useUserStore();

  useEffect(() => {
    if (user) {
      navigation('/dashboard', { replace: true });
    }
  
  },[user, navigation]);

    return (
      <>
        <Box sx={{
          backgroundColor: BASE_BEIGE,
          minHeight: 'calc(100vh - 67px)',
          paddingY: 10,
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          >
            <Snackbar
              open={error !== null}
              autoHideDuration={3000}
              message={error}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
            <Box sx={{
              margin: 'auto',
              backgroundColor: 'white',
              paddingY: 4,
              paddingX: 10,
              borderRadius: '10px',
              minWidth: { xs: '90vw', sm: '70vw', md: '40vw' },
              boxSizing: 'border-box',
            }}>
              {isRegistering ? <Register /> : <Login />}
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                marginTop: 2,
                alignItems: 'center'
              }}>
                <Typography variant="h6">{isRegistering ? '¿Ya tienes una cuenta?' : '¿No tienes una cuenta?'}</Typography>
                <Typography onClick={() => setIsRegistering(!isRegistering)} variant="h6" sx={{ color: 'primary.main', '&:hover': { cursor: 'pointer'} }}>{isRegistering ? 'Inicia sesión' : 'Crea una cuenta'}</Typography>
              </Box>
            </Box>
          </motion.div>
        </Box>
        <Footer />
      </>
    );
}

export default LoginPage;
