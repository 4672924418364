import { Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import moment from 'moment';
import { usePatientStore } from '../../stores/patient.store';
import { useEffect } from 'react';

const CampaignStatus = {
  Taken: 'taken',
  Available: 'available'
};

const PatientGeneralInfo = ({ patient }) => {
  const { setOpenEditModal, onGetPatientCampaigns, campaigns, onUpdateCampaign } = usePatientStore();

  useEffect(() => {
    if (patient) {
      onGetPatientCampaigns(patient._id);
    }
  }, [patient, onGetPatientCampaigns]);

  const genders = {
    male: 'Hombre',
    female: 'Mujer',
    unknown: 'Desconocido'
  }

  const handleCheckboxChange = (checked, id) => {
    if (checked) {
      onUpdateCampaign(id, CampaignStatus.Taken);
    }
  }
    
    return (
    <Box sx={{
      paddingY: 4
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        gap: 2,
        marginBottom: 5
      }}>
        <AccountCircleOutlined sx={{ color: 'primary.main' }} />
        <Typography
          variant="body1"
          sx={{
            fontWeight: 900,
            fontFamily: 'gotham',
            marginTop: '5px'
          }} >Información General</Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Typography variant="body1" sx={{ fontFamily: 'tahoma', fontSize: 12}}>Nombre</Typography>
          <Typography variant="body1" sx={{ fontFamily: 'tahoma', fontWeight: 700, fontSize: 16, marginBottom: 3 }} >{patient.name} {patient.lastname}</Typography>
          <Typography variant="body1" sx={{ fontFamily: 'tahoma', fontSize: 12}}>Género</Typography>
          <Typography variant="body1" sx={{ fontFamily: 'tahoma', fontWeight: 700, fontSize: 16, marginBottom: 3 }} >{genders[patient.gender]}</Typography>
          <Typography variant="body1" sx={{ fontFamily: 'tahoma', fontSize: 12}}>Edad</Typography>
          <Typography variant="body1" sx={{ fontFamily: 'tahoma', fontWeight: 700, fontSize: 16, marginBottom: 3 }} >{moment().diff(patient.birthdate, 'years', false)} años</Typography>
          <Typography variant="body1" sx={{ fontFamily: 'tahoma', fontSize: 12}}>Tipo de Sangre</Typography>
          <Typography variant="body1" sx={{ fontFamily: 'tahoma', fontWeight: 700, fontSize: 16, marginBottom: 3 }} >{patient.bloodType}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, alignItems: 'center'}} onClick={() => setOpenEditModal(true)}>
            <Typography variant="body2" sx={{ marginBottom: 3, color: '#1C4CDF', fontSize: '18px' }} > Ver/editar información general</Typography>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          marginLeft: 'auto',
          flexDirection: 'column',
        }}>
          {campaigns.map(campaign => {
            if (campaign.status === CampaignStatus.Available) {
              return (
                <Box key={campaign._id} sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  marginBottom: 2
                }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleCheckboxChange(e.target.checked, campaign._id)}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={campaign.title}
                  />
                </Box>
              )
            }
            return null;
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default PatientGeneralInfo;
