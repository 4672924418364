import { useState } from "react";
import {
  GridRowModes,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';


export const useEstudiosState = (initialRows) => {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };


  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row._id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row._id === id);
    console.log('editedRow', editedRow);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row._id !== id));
    }
  };

  return {
    rows,
    rowModesModel,
    setRows,
    setRowModesModel,
    handleRowEditStop,
    handleEditClick,
    handleDeleteClick,
    handleCancelClick
  }
}