import { create } from 'zustand';
import { getEstudios, createEstudio, updateEstudio, deleteEstudio } from '../api/estudios.api';

export const useEstudiosStore = create((set, get) => ({
  estudios: [],
  loading: false,
  fetched: false,
  error: null,
  selectedEstudio: null,
  onCreateEstudio: async (data, onSuccess) => {
    try {
      set({ loading: true });
      const estudio = await createEstudio(data);
      set({ estudios: [estudio, ...get().estudios], loading: false });
      onSuccess();
      return estudio;
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onFetchEstudios: async () => {
    try {
      set({ loading: true });
      const estudios = await getEstudios();
      set({ estudios, loading: false, fetched: true });
    } catch (error) {
      set({ loading: false, error: error.message, fetched: true });
    }
  },
  onDeleteEstudio: async (id) => {
    try {
      set({ loading: true });
      await deleteEstudio(id);
      const estudios = get().estudios.filter(estudio => estudio._id !== id);
      set({ estudios, loading: false });
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  onUpdateEstudio: async (id, data) => {
    try {
      set({ loading: true });
      const updatedEstudio = await updateEstudio(id, data);
      const estudios = get().estudios.map(estudio => estudio._id === id ? updatedEstudio : estudio);
      set({ estudios, loading: false });
    } catch (error) {
      set({ loading: false, error: error.message });
    }
  },
  setSelectedEstudio: (estudio) => set({ selectedEstudio: estudio }),
  clearError: () => set({ error: null }),
  clearSelectedEstudio: () => set({ selectedEstudio: null }),
  clearEstudios: () => set({ estudios: [] }),
  clearLoading: () => set({ loading: false }),
  clearAll: () => set({ estudios: [], selectedEstudio: null, loading: false, error: null }),
}));