import axios from 'axios';
import { url } from '../utils/url';

export const getEstudios = async () => {
  try {
    const response = await axios.get(`${url}/api/estudio/all`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const createEstudio = async (data) => {
  try {
    const response = await axios.post(`${url}/api/estudio/create`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getEstudioById = async (id) => {
  try {
    const response = await axios.get(`${url}/api/estudio/${id}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updateEstudio = async (id, data) => {
  try {
    const response = await axios.put(`${url}/api/estudio/${id}`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const deleteEstudio = async (id) => {
  try {
    const response = await axios.delete(`${url}/api/estudio/${id}`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}