import { create } from 'zustand';
import { findAllUsers, login, logout, register, updateUser, verify } from '../api/user.api';

export const useUserStore = create((set, get) => ({
  verified: false,
  loading: false,
  error: null,
  user: null,
  users: [],
  setUser: (user) => set({ user }),
  onLogin: async (loginData) => {
    try {
      set({ loading: true });
      const user = await login(loginData);
      if (user && user.email) {
        set({ user, loading: false });
      } else {
        throw new Error(user.message);
      }
      
    } catch (error) {
      set({ loading: false, error: error.message });
      setTimeout(() => {
        set({ error: null });
      }, 2000);
    }
  },
  onRegister: async (registerData) => {
    try {
      set({ loading: true });
      const user = await register(registerData);
      set({ user, loading: false });
    } catch (error) {
      set({ loading: false });
    }
  },
  onLogout: async (onSuccess) => {
    await logout();
    set({ user: null })
    onSuccess();
  },
  onVerify: async () => {
    try {
      set({ loading: true });
      const user = await verify();
      if (user && user.email) {
        set({ user, loading: false });
      } else {
        throw new Error(user.message);
      }
      set({ user, loading: false, verified: true });
    } catch (error) {
      set({ loading: false, verified: true });
    }
  },
  onGetUsers: async () => {
    try {
      set({ loading: true });
      const users = await findAllUsers();
      set({ users, loading: false });
    } catch (error) {
      set({ loading: false });
    }
  },
  onUpdateUser: async (user, onSuccess) => {
    try {
      set({ loading: true });
      const updatedUser = await updateUser(user);
      const users = get().users;
      const index = users.findIndex(u => u._id === updatedUser._id);
      users[index] = updatedUser;
      set({ users, loading: false });
      onSuccess();
    } catch (error) {
      set({ loading: false });
    }
  }
}));
