import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { registerVariants } from '../utils/animations';
import { useUserStore } from '../stores/user.store';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import logo from '../assets/images/logo.png';

const Register = () => {
  const { onRegister, loading } = useUserStore();
  const [selectedDate, setSelectedDate] = useState(null);
 
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    lastname: Yup.string().required('Required'),
    birthdate: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      lastname: '',
      birthdate: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onRegister(values);
    }
  });

  const handleDateChange = (date) => {
    const d = date.format('YYYY-MM-DD');
    formik.setFieldValue('birthdate', d);
    setSelectedDate(date);
  }

    return (
      <Box sx={{
        width: '100%',
      }}>
        <motion.div
          variants={registerVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
            <img src={logo} alt="logo" style={{ display: 'block', margin: 'auto', marginBottom: '20px', height: '250px' }} />
            <Typography variant="h6" sx={{
              marginBottom: 2
            }} >Crear cuenta</Typography>
            <Typography variant="body1" sx={{
              marginBottom: 10
            }}>Registre su información para crear una cuenta.</Typography>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '0 auto',
                  marginTop: '20px',
                  gap: '20px'
              }} >
                  <TextField name="name" label="Nombre(s)" type="text" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} required />
                  <TextField name="lastname" label="Apellido(s)" type="text" value={formik.values.lastname} onChange={formik.handleChange} onBlur={formik.handleBlur} required />
                  <TextField name="email" label="Correo electrónico" type="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} required />
                  <DatePicker
                    name="birthdate"
                    value={selectedDate}
                    onChange={handleDateChange}
                    required
                    label='Fecha de nacimiento'
                    fullWidth
                    sx={{ width: '100%'}} />
                  <TextField name="password" label="Contraseña" type="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} required/>
                  <Box sx={{
                    marginBottom: 4
                  }}>
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={!(formik.dirty && formik.isValid) || loading} >Crear cuenta</Button>
                </Box>
              </Box>
            </form>
          </div>
        </motion.div> 
      </Box>
    );
}

export default Register;
