import React from 'react';
import { motion } from 'framer-motion';
import { Box, Typography, Snackbar } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePatientStore } from '../stores/patient.store';
import { useUserStore } from '../stores/user.store';
import { pageVariants } from '../utils/animations';
import LoggedInRoute from './loggedin-route';
import { AccountCircleOutlined } from '@mui/icons-material';
import PatientForm from './patient.form';
import { useNavigate } from 'react-router-dom';


const CreatePatient = ({ open }) => {
  const navigation = useNavigate();

  const { onCreatePatient, error } = usePatientStore();
  const { user } = useUserStore();


  const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    lastname: Yup.string().required('Required'),
    birthdate: Yup.string().required('Required'),
  });

  const onClose = () => {
    navigation('/dashboard');
  }
  
  const formik = useFormik({
    initialValues: {
      name: '',
      lastname: '',
      birthdate: '',
      gender: 'undefined',
      height: '',
      weight: '',
      bloodType: '',
      diseases: [],
      emergencyContacts: [
        { name: '', phone: '' }
      ],
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      const data = {
        ...values,
        belongsTo: user._id
      }
      onCreatePatient(data, onSuccess);
    }
  });

  const onSuccess = () => {
    onClose();
    formik.resetForm();
  }

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <LoggedInRoute>
        <Box sx={{
          background: 'linear-gradient(180deg, rgba(225,210,189,1) 28%, rgba(240,239,235,1) 28%)',
          minHeight: 'calc(100vh - 67px)',
          display: 'flex',
          paddingY: {xs: 2, md: 10},
          paddingX: {xs: 4, md: 30},
          boxSizing: 'border-box',
        }} >
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            width: '100%',
            marginX: 'auto',
            backgroundColor: 'white',
            borderRadius: '15px',
            paddingX: 10,
            paddingY: 5,
          }}>
            <Snackbar open={error !== null} autoHideDuration={3000} message={error} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
            <Typography variant='h5' sx={{ fontWeight: 'bold', fontSize: '24px'}} >Nuevo Perfil</Typography>
            <Typography variant="body1">Completa la siguiente información para dar de alta tu nuevo perfil.</Typography>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              gap: 2,
            }}>
              <AccountCircleOutlined sx={{ color: 'primary.main' }} />
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 900,
                  fontFamily: 'gotham',
                  marginTop: '5px'  
                }} >Información General</Typography>
            </Box>
            <PatientForm formik={formik} />
          </Box>
        </Box>
      </LoggedInRoute>
    </motion.div>
  );
}

export default CreatePatient;
