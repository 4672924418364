import { Box } from "@mui/material";
import { useRef, useState } from "react";
import { DescriptionOutlined } from '@mui/icons-material';
import { BASE_BROWN } from "../utils/constants";

const UploadFile = ({ file, setFile }) => {
    const [error, setError] = useState(null);
    const [background, setBackground] = useState('#fff');
    const inputRef = useRef();

    const handleFileChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let selected = e.target.files[0];
        // const types = ['image/png', 'image/jpeg', 'file/pdf', 'file/docx', 'file/doc', 'image/jpg', 'application/pdf'];

        if (selected ) {
            handleFile(selected);
            setError('');
        } else {
            setFile(null);
            setError('Selecciona un archivo válido (png, jpg, pdf, docx, doc)');
            selected = null;
        }
    };

    const ondragOver = (e) => {
      e.preventDefault();
      setBackground('#f1f1f1');
    };

    const onDrop = (e) => {
        e.preventDefault();
        setBackground('#fff');

        const files = e.dataTransfer.files;
        if (files.length) {
            let selected = files[0];
            const types = ['image/png', 'image/jpeg', 'file/pdf', 'file/docx', 'file/doc', 'image/jpg', 'application/pdf'];

            if (selected) {
                handleFile(selected);
                setError('');
            } else {
                setFile(null);
                setError('Selecciona un archivo válido (png, jpg, pdf, docx, doc)');
            }
        }
    };

    const handleFile = (file) => {
      setFile(file);
    }
    return (
      <Box
        onClick={() => inputRef.current.click()}
        onDragOver={ondragOver}
        onDrop={onDrop}
        sx={{
          width: '250px',
          height: '250px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px dashed #aaa',
          borderRadius: '10px',
          cursor: 'pointer',
          margin: '0 auto',
          textAlign: 'center',
          fontSize: '1.5rem',
          color: '#aaa',
          background: background
        }}
      >
        {!file && (
          <label htmlFor="file" onClick={(e) => e.preventDefault()} >Suelta un archivo aqui o da click para subir</label>
        )}
        <input type="file" onChange={handleFileChange} id="file" style={{
          display: 'none',
          height: 0,
          width: 0,
        }} ref={inputRef} />
        {error && <div>{error}</div>}
        {file && <DescriptionOutlined sx={{ fontSize: '100px', color: BASE_BROWN }} />}
        {file && <div>{file.name.length > 15 ? `${file.name.substring(0, 12)}...` : file.name}</div>}
      </Box>
    );
}

export default UploadFile;
