import React from 'react';
import { useUserStore } from '../stores/user.store';
import { Box, CircularProgress } from '@mui/material';
import { usePatientStore } from '../stores/patient.store';

const Loading = () => {
  const { loading } = useUserStore();
  const { loading: userLoading } = usePatientStore();

  if (loading || userLoading) {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.5)',
        }}>
          <CircularProgress />
        </Box>
    );
  }
  return null;
}

export default Loading;
