
export const loginVariants = {
  initial: {
    opacity: 0,
    x: '100vw'
  },
  animate: {
    opacity: 1,
    x: 0
  },
  exit: {
    opacity: 0,
    x: '-100vw'
  }
}

export const registerVariants = {
  initial: {
    opacity: 0,
    x: '-100vw'
  },
  animate: {
    opacity: 1,
    x: 0
  },
  exit: {
    opacity: 0,
    x: '100vw'
  }
}

export const pageVariants = {
  initial: {
    opacity: 0,
    y: '-100vh',
    transition: { duration: 0.5 }
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { duration: 1 }
  },
  exit: {
    opacity: 0,
    y: '100vh',
    transition: { duration: 0.5 }
  }
}

export const createPatientVariants = {
  initial: {
    opacity: 0,
    x: '-100vw'
  },
  animate: {
    opacity: 1,
    x: 0
  },
  exit: {
    opacity: 0,
    x: '100vw'
  }
}