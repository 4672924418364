import { Box, Dialog, Typography, Button, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useUserStore } from '../../stores/user.store';

const roles = ['superadmin', 'client'];

const UsersAdminComponent = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editModal, setEditModal] = useState(false);

  const { users, onUpdateUser, user, onGetUsers } = useUserStore();

  useEffect(() => {
    const onRoleChange = (e) => {
      setSelectedRole(e.target.value);
      if (selectedUser?.role !== e.target.value) {
        setEditModal(true);
      }
    }
  
    if (users.length > 0) {
      const rows = users.map(user => {
        return {
          id: user._id,
          name: user.name,
          email: user.email,
          role: user.role,
          birthdate: user.birthdate
        }
      });
      setRows(rows);
      const cols = [
        { field: 'name', headerName: 'Nombre', width: 200 },
        { field: 'email', headerName: 'Email', width: 200, },
        { field: 'birthdate', headerName: 'Fecha de nacimiento', width: 200 },
        { field: 'role', headerName: 'Rol', width: 200, editable: true,
          renderCell: (params) => (
            <Select
              value={params.value}
              onChange={onRoleChange}
              fullWidth
              name="role"
              id='role'
              >
              {roles.map(role => (
                <MenuItem key={role} value={role}>{role}</MenuItem>
              ))}
            </Select>
          )
         },
      ] 
      setColumns(cols);
    }
  }, [users, selectedUser]);

  useEffect(() => {
    if ((user.role === 'superadmin') && users.length < 1) {
      onGetUsers();
    }
  }, [users, user.role, onGetUsers]);

  const applyRoleChange = () => {
    const updated = { ...selectedUser };
    updated.role = selectedRole;
    onUpdateUser(updated, () => setEditModal(false));
  }

  const onCellClick = (param, event) => {
    event.preventDefault();
    setSelectedUser({ ...users.find(user => user._id === param.id) });
  }

  if (users.length < 1) {
    return <Typography variant='h5'>No hay usuarios</Typography>
  }

  return (
    <Box sx={{}}>
      <Dialog open={editModal} onClose={() => setEditModal(false)} >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
          backgroundColor: 'white',
          borderRadius: '15px',
          paddingX: 10,
          paddingY: 5,
        }}>
          <Typography variant='h5'>Confirma el cambio</Typography>
          <Typography variant='body1'>¿Estás seguro de que deseas cambiar el rol de {selectedUser?.name}?</Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
            justifyContent: 'center'
          }}>
            <Button variant="contained" color="primary" onClick={() => setEditModal(false)} >Cancelar</Button>
            <Button variant="contained" color="primary" onClick={applyRoleChange} >Confirmar</Button>
          </Box>
        </Box>
      </Dialog>
      <DataGrid rows={rows} columns={columns} pageSize={5} onCellClick={onCellClick} />
    </Box>
  )
}

export default UsersAdminComponent;
