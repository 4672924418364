import { Add } from "@mui/icons-material";
import { Box, Avatar } from "@mui/material"

const CircledAvatar = ({ patient, selected }) => {  

  if (!patient) return null;
  if (patient.name === 'Nuevo Perfil') {
    return (
      <Box sx={{
        borderWidth: 1,
        borderColor: '#B0704D',
        borderStyle: 'solid',
        borderRadius: '50%',
        padding: 1,
      }}>
        <Avatar sx={{
          width: {xs: 50, md: 100},
          height: {xs: 50, md: 100},
          backgroundColor: '#B0704D',
          color: 'white',
          fontSize: 40,
        }}>
          <Add sx={{ fontSize: { xs: 20, md: 40 }}} />
        </Avatar>
      </Box>
    )
  }

  return (
    <Box sx={{
      borderWidth: selected ? 3 : 1,
      borderColor: '#B0704D',
      borderStyle: 'solid',
      borderRadius: '50%',
      padding: 1,
    }}>
      <Avatar sx={{
        width: {xs: 50, md: 100},
        height: {xs: 50, md: 100},
        backgroundColor: '#B0704D',
        color: 'white',
        fontSize: {xs: 20, md: 40},
      }}>
        {patient && patient.url ? (
          <img src={patient.url} alt="Patient" width="100%" />
        ) : (
          `${patient.name[0]} ${patient.lastname[0]}`.toUpperCase()
        )}
      </Avatar>
    </Box>
  )
}

export default CircledAvatar;
