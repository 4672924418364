import axios from 'axios';
import { url } from '../utils/url';

export const getProductos = async () => {
  try {
    const response = await axios.get(`${url}/api/stripe/product/all`, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const createProducto = async (data) => {
  try {
    const response = await axios.post(`${url}/api/stripe/product/create`, data, { withCredentials: true });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}